<template>
  <div class="map-view-com">
    <div class="position-relative">
      <div
        id="map"
        class="map-div p-2"
      />
    </div>
  </div>

</template>

<script>
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier'
import {
  drawCircle,
  createResetZoomButton,
  devicesMarkerFunc,
  markerclusterer,
  moveToLocation,
} from '@/common/global/mapFunctions'
import darkThemeStyle from '@/common/global/darkThemeMap'

export default {
  components: {
  },
  props: {
    id: {
      type: String || Function || HTMLInputElement,
      required: true,
    },
    lat: {
      type: Number,
      default: 31.530443164751656,
    },
    lng: {
      type: Number,
      default: 74.37880084073794,
    },
    zoomLevel: {
      type: Number,
      default: 10,
    },
    selectedDeviceIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      marker: [],
      markers: [],
      map: '',
      circle: {},
      selectedRadius: 0,
      center: { lat: this.lat, lng: this.lng },
      zoomLevelLocal: this.zoomLevel,
      locationAddress: '',
      selectedMarkerIndex: undefined,
      ifAllSelected: [],
      allDevicesFromParent: [],
      isDeviceSelected: false,
      currentInfoWindow: null,
      svgMarker: {
        path: 'M384,1.42108547e-14 L384,298.666667 L213.333,298.666 L213.333,362.666 L298.666667,362.666667 L298.666667,405.333333 L85.3333333,405.333333 L85.3333333,362.666667 L170.666,362.666 L170.666,298.666 L1.42108547e-14,298.666667 L1.42108547e-14,1.42108547e-14 L384,1.42108547e-14 Z M341.333333,42.6666667 L42.6666667,42.6666667 L42.6666667,256 L341.333333,256 L341.333333,42.6666667 Z M320,64 L320,234.666667 L64,234.666667 L64,64 L320,64 Z',
        fillColor: 'red',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.05,
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(200.333333, 20.6666667),
        markerClusterer: null,
      },
      selectedSvgMarker: {
        path: 'M384,1.42108547e-14 L384,298.666667 L213.333,298.666 L213.333,362.666 L298.666667,362.666667 L298.666667,405.333333 L85.3333333,405.333333 L85.3333333,362.666667 L170.666,362.666 L170.666,298.666 L1.42108547e-14,298.666667 L1.42108547e-14,1.42108547e-14 L384,1.42108547e-14 Z M341.333333,42.6666667 L42.6666667,42.6666667 L42.6666667,256 L341.333333,256 L341.333333,42.6666667 Z M320,64 L320,234.666667 L64,234.666667 L64,64 L320,64 Z',
        fillColor: 'green',
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 0.05,
        // eslint-disable-next-line no-undef
        anchor: new google.maps.Point(200.333333, 20.6666667),
        markerClusterer: null,
      },
      oms: null,
    }
  },
  computed: {
    areAllDevicesSelected() {
      return this.markers.length > 0 && this.ifAllSelected.length === this.markers.length
    },
  },
  watch: {
    locationAddress() {
      this.$emit('locationAddressSetFunc', this.locationAddress)
    },
    zoomLevel: {
      async handler() {
        this.center = {
          lat: this.lat, lng: this.lng,
        }
        this.zoomLevelLocal = this.zoomLevel
        await this.initMap()
      },
    },
  },
  mounted() {
    this.initMap()
  },
  methods: {
    selectedRadiusFunc(value) {
      this.selectedRadius = value
      this.circle.setMap(null)
      this.circleFunc()
      this.distanceChanged(this.selectedRadius)
    },
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: this.center.lat, lng: this.center.lng },
        zoom: this.zoomLevelLocal,
        mapTypeId: 'roadmap',
        streetViewControl: false,
        styles: darkThemeStyle,
        // mapTypeControl: false,
      })
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        keepSpiderfied: true, // Keeps the spiderfied markers expanded until clicked outside
        nearbyDistance: 50, // Pixels threshold for clustering
        circleFootSeparation: 100, // Increased separation in circular layout
        spiralFootSeparation: 200, // Increased separation in spiral layout
        spiralLengthStart: 20, // Starting length for the spiral
        spiralLengthFactor: 30, // Spiral expansion factor
        markersWontHide: true,
        markersWontMove: true,
        circleSpiralSwitchover: Infinity,
        spiderfiedShadowColor: '#f7900a',
        nudgeStackedMarkers: false,
      })

      this.oms.legColors.usual.roadmap = '#ffffff'
      this.oms.legColors.highlighted.roadmap = '#f7900a'

      // Attach a listener for spiderfied markers
      this.oms.addListener('click', marker => {
        const { device } = marker // Pass your device data here
        this.onMarkerClick(device) // Reuse your existing method for showing InfoWindow
      })

      this.oms.addListener('spiderfy', () => {
        console.log('Markers spiderfied!')
      })

      this.oms.addListener('unspiderfy', () => {
        console.log('Markers unspiderfied!')
      })

      //   // Create the search box and link it to the UI element.
      const input = document.getElementById(this.id)
      // eslint-disable-next-line no-undef
      const searchBox = new google.maps.places.SearchBox(input)
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds())
      })
      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()
        // For each place, get the icon, name and location.
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds()

        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log('Returned place contains no geometry')
            return
          }
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
          this.center.lat = place.geometry.location.lat()
          this.center.lng = place.geometry.location.lng()
          this.locationAddress = place.formatted_address
          this.$emit('circleCenterSetFunc', this.center)
          this.$emit('locationAddressSetFunc', this.locationAddress)
        })
        this.map.fitBounds(bounds)
        this.map.setZoom(11)
      })
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })

      createResetZoomButton(this.map, this.resetZoom)
    },

    circleFunc() {
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    distanceChanged(val) {
      this.circle.set('radius', Number(val * 1000))
    },
    changeHandler() {
      this.circle.setMap(null)
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.selectedRadius = 0
    },
    devicesMarkerFunc(devicesPaths) {
      this.allDevicesFromParent = devicesPaths
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.markers = []

      if (this.markerClusterer) {
        this.markerClusterer.clearMarkers()
        this.markerClusterer = null
      }

      const markers = devicesMarkerFunc(
        this.map,
        devicesPaths,
        this.svgMarker,
        this.selectedSvgMarker,
        this.selectedDeviceIds,
        this.onMarkerClick,
      )
      this.markers = markers

      this.markers.forEach(marker => {
        this.oms.addMarker(marker)
      })

      this.markerClusterer = markerclusterer(this.markers, this.map)
    },
    drawCircle(lat, lng) {
      this.circle = drawCircle(this.map, lat, lng, this.selectedRadius)
      this.circle.setMap(this.map)
    },
    onMarkerClick(device) {
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close()
      }
      if (this.selectedMarkerIndex !== undefined) {
        this.markers[this.selectedMarkerIndex].setIcon('http://maps.google.com/mapfiles/ms/icons/green-dot.png')
      }

      this.isDeviceSelected = this.selectedDeviceIds.some(d => d.id === device.id)
      const buttonText = this.isDeviceSelected ? 'Unselect' : 'Select'
      // eslint-disable-next-line no-undef
      const infoWindow = new google.maps.InfoWindow({
        content: `<div class="d-flex flex-column justify-content-center"><strong>Device Name : ${device.name}</strong><br>
            <strong>Device ID: ${device.id}</strong><br>
            Lat: ${device.lat}, Lng: ${device.lng}
            <button id="select-location-btn" class="btn btn-primary justify-content-center mx-4 mt-2">${buttonText}</button></div>
            `,
        ariaLabel: 'Device',
      })

      infoWindow.setPosition({ lat: device.lat, lng: device.lng })
      infoWindow.open(this.map)
      this.currentInfoWindow = infoWindow

      // eslint-disable-next-line no-undef
      google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
        document.getElementById('select-location-btn').addEventListener('click', async () => {
          const markerToUpdate = this.markers.findIndex(marker => marker.id === device.id)
          if (this.isDeviceSelected) {
            // const selectedColor = createColorMarker(this.svgMarker, 'red')
            this.markers[markerToUpdate].setIcon(this.svgMarker)
            this.unselectLocation(device)
          } else {
            // const selectedColor = createColorMarker(this.svgMarker, 'green')
            this.markers[markerToUpdate].setIcon(this.selectedSvgMarker)
            this.selectLocation(device)
          }
          infoWindow.close()
        })
      })
    },
    selectLocation(device) {
      this.$emit('locationSelected', device)
    },
    unselectLocation(device) {
      this.$emit('locationRemoved', device)
    },
    markMarkerUselected(device) {
      const markerToUpdate = this.markers.findIndex(marker => marker.id === device.id)
      // const selectedColor = createColorMarker(this.svgMarker, 'red')
      this.markers[markerToUpdate].setIcon(this.svgMarker)
      this.unselectLocation(device)
    },
    moveToLocation(lat, lng) {
      moveToLocation(this.map, lat, lng)
    },
    resetZoom() {
      this.map.setZoom(this.zoomLevel)
    },
    selectAllDevices() {
      this.ifAllSelected = [...this.allDevicesFromParent]
      this.markers.forEach(marker => {
        marker.setIcon(this.selectedSvgMarker)
        this.$emit('allDevicesSelected', this.ifAllSelected)
      })
    },
    deselectAllDevices() {
      this.ifAllSelected = []
      this.markers.forEach(marker => {
        marker.setIcon(this.svgMarker)
        this.$emit('allDevicesDeselected')
      })
    },
  },
}
</script>

  <style lang="scss">
  @import "src/assets/scss/style";
  .pac-container {
    z-index: 5 !important;
  }
  .map-view-com {
    #map {
      height: 100%;
    }

    /* Optional: Makes the sample page fill the window. */
    html,
    body {
      height: 100%;
      margin: 0;
      padding: 0;
    }
  }
  </style>
